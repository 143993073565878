import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function BussSpon() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>BUSINESS SPONSORSHIP</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        We're here to offer sponsor local service agent services for UAE and GCC entities, covering activities like setting up investment vehicles, establishing companies, serving as a registered agent for RAKICC (Ras Al Khaimah International Corporate Centre), handling licensing, general administration, and conducting negotiations on behalf of clients.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default BussSpon;
