import React from 'react';
import { MdCall } from "react-icons/md";

class Callbtn extends React.Component {
  handleCall = () => {
    window.location.href = 'tel:+971581785456'; 
  };

  render() {
    const buttonStyle = {
      position: 'fixed',
      top: '30px',
      right: '20px',
      backgroundColor: '#E7D49E',
      color: 'black',
      borderRadius: '30%',
      width: '40px',
      height: '40px',
      border: 'none',
      fontSize: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    };

    return (
      <button style={buttonStyle} onClick={this.handleCall}>
        <MdCall />
      </button>
    );
  }
}

export default Callbtn;
