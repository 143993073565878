import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function InsurancePlan() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Financial Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        Having insurance planning in place is crucial for safeguarding assets and income in case of unforeseen circumstances like death or illness. Financial consultants play a vital role in assisting clients in selecting the appropriate insurance type and coverage that best aligns with their specific needs.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default InsurancePlan;
