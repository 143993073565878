import React from 'react';
import './Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from './Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

function DisputeResol() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Legal Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
          <h3>Dispute Resolution</h3><br />
        At True View, we understand that disputes can disrupt your business operations and impact your bottom line. Our Dispute Resolution Services are designed to provide effective and efficient solutions to resolve conflicts, recover debts, and protect your interests. Our experienced legal team is dedicated to guiding you through various dispute resolution processes with professionalism and expertise.
        </p>
        <div id='newxz'>
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          slidesPerGroup={1}
          navigation
          loop={true}
          // autoplay={{
          //   delay: 2000,
          //   disableOnInteraction: true,
          // }}
          modules={[Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }} // Use the Navigation and Autoplay modules
        >
          
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Debt recovery</h5>
              <p>
              Recovering outstanding debts is crucial for maintaining cash flow and financial stability. Our debt recovery services include demand letters, negotiation, and legal action to ensure you receive the payments you are owed. We employ a strategic approach to debt recovery, aiming for swift and amicable resolutions while being prepared to take decisive action when necessary.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Coordinating litigation in the local courts via our local advocate firm</h5><br />
              <p>
              We coordinate litigation efforts through our trusted local advocate firm, ensuring that your case is handled with the highest level of competence and professionalism. From initial filings to court representation, we manage the entire litigation process to achieve the best possible outcome for you.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Arbitration</h5><br />
              <p>
              Our team provides comprehensive arbitration services, including selecting arbitrators, preparing for hearings, and representing your interests throughout the arbitration process. We strive to resolve disputes efficiently while maintaining confidentiality and minimizing disruption to your business.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Commercial dispute resolution</h5><br />
              <p>
              Commercial disputes can arise in various aspects of business operations, from contract breaches to partnership disagreements. We offer expert legal support in resolving commercial disputes through negotiation, mediation, and litigation if necessary. Our approach is to find practical and cost-effective solutions that preserve business relationships and protect your interests.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
      </div>
      <div className='slide3'>
                    <h5 id='quote'>"Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work."</h5>
                    <p>Steve Jobs</p>
                </div>
      <Footer />
    </div>
  );
}

export default DisputeResol;
