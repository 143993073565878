import React from 'react';
import './Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from './Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

function CorporateCommerical() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Legal Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
          <h3>Corporate and Commercial Services</h3><br />
        At True View, our Corporate and Commercial Services are designed to support businesses at every stage of their growth. We provide comprehensive legal solutions to help your company navigate the complexities of the corporate world with confidence and ease. Our expert team is dedicated to delivering tailored advice and practical solutions that align with your business objectives.
        </p>
        <div id='newxz'>
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          slidesPerGroup={1}
          navigation
          loop={true}
          // autoplay={{
          //   delay: 2000,
          //   disableOnInteraction: false,
          // }}
          modules={[Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }} // Use the Navigation and Autoplay modules
        >
          
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Commercial contract drafting and reviewing</h5>
              <p>
              Our team specializes in drafting and reviewing commercial contracts to ensure that your agreements are robust, clear, and enforceable. We help you mitigate risks and protect your interests by providing meticulous attention to detail and a deep understanding of your business needs.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Shareholder arrangements</h5>
              <p>
              We assist in structuring and negotiating shareholder arrangements that define the rights, responsibilities, and obligations of all parties involved. Our goal is to facilitate harmonious relationships and safeguard your investments through well-crafted agreements.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Company formation</h5>
              <p>
              Starting a new business can be a daunting process. We streamline company formation by guiding you through the legal requirements and administrative processes. From selecting the appropriate business structure to registering your company, we ensure a smooth and compliant start.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Procurement and tendering</h5>
              <p>
              Our expertise in procurement and tendering helps you secure the best value for your business. We provide legal support throughout the procurement process, from drafting tender documents to evaluating bids and negotiating contracts, ensuring transparency and fairness.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'> 
              <h5>Franchise and marketing arrangement</h5>
              <p>Expanding your business through franchising or strategic marketing arrangements requires careful planning and legal oversight. We offer comprehensive services to draft, review, and negotiate franchise agreements and marketing contracts, ensuring compliance and protecting your brand.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Terms and conditions and sale agreements</h5>
              <p>Clear and enforceable terms and conditions are essential for any business transaction. We draft and review terms and conditions, as well as sale agreements, to safeguard your interests and minimize potential disputes with customers and partners.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Financial and services agreements</h5>
              <p>Our team provides expert legal advice on financial and services agreements, ensuring that your contracts are aligned with your business goals and comply with regulatory requirements. We help you navigate complex financial transactions with confidence.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'> 
              <h5>Distribution, agency and licensing agreements</h5>
              <p>We offer legal support for drafting and negotiating distribution, agency, and licensing agreements that drive your business forward. Our services ensure that these agreements are tailored to your specific needs and provide a solid foundation for successful business relationships.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Corporate governance</h5>
              <p>Expanding your business into new markets requires careful planning and legal oversight. 
              Strong corporate governance is crucial for the long-term success of your business. We assist in developing and implementing governance frameworks that promote transparency, accountability, and ethical behavior within your organization. Our services include advising on board structures, compliance, and risk management.</p>
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
      </div>
      <div className='slide3'>
                    <h5 id='quote'>"Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work."</h5>
                    <p>Steve Jobs</p>
                </div>
      <Footer />
    </div>
  );
}

export default CorporateCommerical;
