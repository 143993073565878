import React from 'react';
import './Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from './Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

function RealEstate() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Legal Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
          <h3>Real Estate</h3><br />
        True View offers comprehensive legal services in the real estate sector, providing expert guidance and support for all types of property transactions and disputes. Our experienced team is dedicated to protecting your interests and ensuring that your real estate dealings are secure, compliant, and successful.
        </p>
        <div id='newxz'>
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          slidesPerGroup={1}
          navigation
          loop={true}
          // autoplay={{
          //   delay: 2000,
          //   disableOnInteraction: true,
          // }}
          modules={[Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }} // Use the Navigation and Autoplay modules
        >
          
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Drafting and reviewing property documentation </h5><br />
              <p>
              We specialize in drafting and reviewing property documentation for all types of real estate transactions, including sales, purchases, leases, and financing agreements. Our meticulous approach ensures that your documents are clear, comprehensive, and legally sound, protecting your interests and minimizing the risk of disputes.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Representing clients at RERA</h5><br />
              <p>
              We represent clients at the Real Estate Regulatory Agency (RERA), providing skilled advocacy and strategic advice to resolve disputes and ensure compliance with all regulatory requirements. Our goal is to achieve favorable outcomes while safeguarding your rights and investments.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Construction matters</h5><br />
              <p>
              We offer legal support in all aspects of construction law, including contract negotiation, project management, dispute resolution, and regulatory compliance. Our services are designed to help you manage risks, adhere to timelines, and achieve successful project completion.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Leasing matters</h5><br />
              <p>
              We provide comprehensive services for drafting, reviewing, and negotiating lease agreements, ensuring that your leasing arrangements are clear, fair, and legally enforceable. Our expertise covers residential, commercial, and industrial leases.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
      </div>
      <div className='slide3'>
                    <h5 id='quote'>"Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work."</h5>
                    <p>Steve Jobs</p>
                </div>
      <Footer />
    </div>
  );
}

export default RealEstate;