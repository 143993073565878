import React from 'react';
import './Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from './Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

function Ecommerce() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Legal Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
          <h3>E-Commerce</h3><br />
        In the dynamic world of e-commerce, True View offers specialized legal services to help you build a strong and legally compliant online presence. Our comprehensive e-commerce solutions are designed to address the unique challenges of digital business, ensuring your operations are secure, compliant, and successful.
        </p>
        <div id='newxz'>
        <Swiper
           spaceBetween={30}
           slidesPerView={4}
           slidesPerGroup={1}
           navigation
           loop={true}
          //  autoplay={{
          //    delay: 2000,
          //    disableOnInteraction: true,
          //  }}
           modules={[Navigation]}
           breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }} // Use the Navigation and Autoplay modules
        >
          
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Website terms and conditions (AR-EN)</h5><br />
              <p>
              Creating clear and enforceable terms and conditions for your website is essential to protect your business and manage user expectations. We draft comprehensive website terms and conditions in both Arabic and English, tailored to your specific business model, ensuring compliance with local and international regulations.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>E-commerce contracts</h5><br />
              <p>
              Our team provides expert legal support in drafting, reviewing, and negotiating e-commerce contracts. These include agreements with suppliers, vendors, and service providers, ensuring that your interests are protected and that all parties understand their rights and obligations.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Intellectual property protection</h5><br />
              <p>
              Protecting your intellectual property is a must in the digital marketplace. We offer services to safeguard your trademarks, copyrights, patents, and trade secrets, helping you prevent unauthorized use and infringement. Our team also provides guidance on developing strategies to manage and enforce your IP rights effectively.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Consumer protection</h5><br />
              <p>
              Ensuring the protection of your consumers is not only a legal requirement but also builds trust and credibility. We provide legal advice on consumer protection laws and help you develop policies and procedures that comply with relevant regulations. This includes guidance on product safety, fair trading practices, and handling consumer complaints.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'> 
              <h5>Franchise and marketing arrangement</h5><br />
              <p>Expanding your business through franchising or strategic marketing arrangements requires careful planning and legal oversight. We offer comprehensive services to draft, review, and negotiate franchise agreements and marketing contracts, ensuring compliance and protecting your brand.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Privacy Policy (AR-EN)</h5><br />
              <p>With increasing concerns about data privacy, having a robust privacy policy is essential. We draft detailed privacy policies in both Arabic and English, ensuring that your practices comply with data protection laws and that your customers' personal information is handled responsibly. Our services include advising on data collection, storage, and sharing practices to mitigate risks and maintain compliance.</p>
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
      </div>
      <div className='slide3'>
                    <h5 id='quote'>"Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work."</h5>
                    <p>Steve Jobs</p>
                </div>
      <Footer />
    </div>
  );
}

export default Ecommerce;
