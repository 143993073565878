import React, { useRef } from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';
import lgImg from '../assets/per.png';
import conImg from '../assets/Team1.png';
import busImg from '../assets/per.png'
import sign2 from '../assets/per.png';
import sign3 from '../assets/t4.png'
import sign4 from '../assets/per.png';
import Slide from '../Pages/Slide';

function Team() {

    const slide1Ref = useRef(null);
    const slide2Ref = useRef(null);
    const slide3Ref = useRef(null);

    const scrollToSlide = (ref) => {
        ref.current.scrollIntoView ({ behavior: 'smooth' });
    };

    return (
        <div className='legal'>
            <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
            <img className='le-bg' src={legal} alt='leg' />
            <h1>Our Team</h1>

            <div className='row' id='Counsel1'>
                <p id='mainpr'>
                    <h4>Our Team</h4><br />
                    We are expertise in both legal and financial services. More than just experts in their practice areas,
                    our people are dedicated, engaged professionals who take the time to understand our clients.
                    Each member brings a unique set of skills and a wealth of experience to ensure we provide comprehensive and
                    tailored solutions to our clients.
                </p>

                <div className='slide2'>
                    <div className='row' id='ot-box'>
                        <div className='column' id='is-box' onClick={() => scrollToSlide(slide1Ref)}>
                            <img src={lgImg} className='home-img' alt='Legal Consulting' />
                            <span style={{color: '#fff'}}>Person</span>
                        </div>
                        <div className='column' id='is-box' onClick={() => scrollToSlide(slide2Ref)}>
                            <img src={conImg} className='home-img' alt='Financial Consulting' />
                            <span style={{color: '#fff'}}>Mahmoud Mahrous<br /><br /><br />Legal Consultant</span><br />
                        </div>
                        <div className='column' id='is-box' onClick={() => scrollToSlide(slide3Ref)}>
                            <img src={busImg} className='home-img' alt='Business Setup' />
                            <span style={{color: '#fff'}}>Person</span>
                        </div>
                    </div>
                </div></div>

                
                <div className='slide3'>
                <h5 id='quote'>"Teamwork is the secret that makes common people achieve uncommon results."</h5>
                <p>Ifeanyi Enoch</p>
                </div>

                <div className='row' id='Counsel1'>
                <Slide ref={slide2Ref}>
                    <div className='row' id='le2'>
                        <div className='column' id='cox'>
                            <img src={sign3} alt='Conf' id='ogimg'></img>
                        </div>
                        <div className='column' id='cox'>
                            <h4 id='lopki'>Mahmoud Mahrous</h4>
                            <p id='lopki'>
                                Mahmoud Mahrous, a distinguished graduate of Alexandria University, has been a practicing lawyer since 2019. With an LL.B. from Alexandria University, Mahmoud has built a robust legal career grounded in extensive experience and expertise.
                                Mahmoud has also been working towards becoming a qualified solicitor in the UK and Wales, further broadening his legal qualifications and international expertise.
                                Starting his journey at well-known local law firms and later serving as an in-house lawyer for the largest luxury retail operator in the Middle East, Mahmoud has played a crucial role in developing and protecting high-end brands across the region. His tenure in these positions has given him invaluable insights into the legal complexities of luxury retail and brand management.
                                Today, Mahmoud is the external Legal Counsel for several prominent companies based in the UAE. Leading the corporate department, he specializes in commercial law, corporate restructuring, and labor matters. His deep understanding and strategic approach in these areas have made him a trusted advisor to his clients.
                                Under Mahmoud's leadership, our firm now proudly serves over 200 major clients across diverse sectors, including retail, healthcare, consulting, and construction. This impressive client base is a testament to the trust and confidence that businesses place in our legal expertise.
                                {/* <span ><Link to='/counsel'>learn more</Link></span> */}
                            </p>
                        </div>
                    </div>
                </Slide>

                <Slide ref={slide1Ref}>
                <div className='row' id='le2'>
                    <div className='column' id='cox'>
                        <h4 id='lopki'>Person</h4>
                        <p id='lopki'>At True View, our Corporate and Commercial Services are designed to support businesses at every stage of their growth. We provide comprehensive legal solutions to help your company navigate the complexities of the corporate world with confidence and ease. Our expert team is dedicated to delivering tailored advice and practical solutions that align with your business objectives...
                            {/* <span ><Link  to='/corporate&commerical'>learn more</Link></span> */}
                        </p>
                    </div>
                    <div className='column' id='cox'>
                        <img src={sign2} alt='Conf' id='ogimg'></img>
                    </div>
                </div></Slide>

                <Slide ref={slide3Ref}>
                <div className='row' id='le2'>
                <div className='column' id='cox'>
                        <img src={sign4} alt='Conf' id='ogimg'></img>
                    </div>
                    <div className='column' id='cox'>
                        <h4 id='lopki'>Person</h4>
                        <p id='lopki'>At True View, our Corporate and Commercial Services are designed to support businesses at every stage of their growth. We provide comprehensive legal solutions to help your company navigate the complexities of the corporate world with confidence and ease. Our expert team is dedicated to delivering tailored advice and practical solutions that align with your business objectives...
                            {/* <span ><Link  to='/corporate&commerical'>learn more</Link></span> */}
                        </p>
                    </div>
                </div></Slide>

            </div>
            <div className='slide3'>
                <h5 id='quote'>"Great things in business are never done by one person. They're done by a team of people."</h5>
                <p>Steve Jobs</p>
            </div>

            <Footer />
        </div>
    );
}

export default Team;