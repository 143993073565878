import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function FzOnshore() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>FREE ZONE & OFFSHORE COMPANY REGISTRATION</h1> 
      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        We have vast expertise in forming various free zone and offshore businesses in the UAE and internationally. Our range of services includes setting up companies in free zones and offshore locations, providing registered agent services, handling license renewals and amendments, managing share transfers, appointing and removing officers, drafting and revising memorandum and articles of association, as well as assisting with liquidation and de-registration processes.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default FzOnshore;
