import React from 'react'
import '../Pages/Style.css';

const Privacy = () => {
  return (
    <div className='privacy'>
      <h4>Privacy Policy</h4>
            <p>Thank you for visiting the website for Legal and Financial Consulting.  This Privacy Policy (“the “Policy”) applies to the trueview.ae website (hereinafter the “Website”) for Legal and Financial Consulting, (hereinafter the “Firm”) and governs data collection and usage.  This Policy may be updated from time to time at the discretion of the Firm.  By using the Website, you acknowledge and agree to the terms and conditions as outlined herein. This Policy describes the Firm’s privacy practices regarding information collected from users of this Website.

            This Policy describes what information is collected and how that information is used.  Because this Policy only applies to this Website, you should examine the privacy policy of any other website that you access using this Website.

            Collection of Information
            The Firm may collect and store data and information which you provide, and which is collected through your interaction with the Website including, but not limited to, personally identifiable information such as your name, email address, work address, telephone number, demographic information, age, gender, preferences, interests, and other information that you may provide.  The Firm may also collect information related to your computer and usage such as your IP address, computer hardware and software, browser type, domain names, access dates and times, and referring websites.  The Firm may use technologies that automatically collect, store, and send “cookies” or other anonymous identifiers to your device.  The Firm may use and store this information to operate its services, maintain the quality of the Website, and gather and analyze general data collected.

            The Firm may contract with third-party service providers to better understand the Firm’s Website visitors.  The Firm does not control how third parties use or store data, or technologies related to data collection.
            Using Personal Information
            The Firm may collect and use your personal information to inform you of products and services, to conduct research and surveys, respond to emails or other electronic messages, send newsletters, announcements, and similar material, improve your usability of the Website, provide service(s) requested by you, keep you informed about events, and to better serve you.

            The Firm will not sell its client list to third parties.  The Firm does not sell your personally identifiable information to third parties except: where you have given your express consent; the Firm, in its good-faith judgment, determines it needs to provide your information to a third party to deliver services you requested; disclosure is required by law or to protect the rights, property, or personal safety of personnel of the Firm, clients or members of the general public.

            The Firm may track pages visited within the Website for marketing and business purposes.  The Firm uses this data to curate and deliver its content and services.
            Cookies and Google Analytics
            The Website may use Google Analytics, a web analytics service provided by Google, Inc. (“Google”).  Google uses “cookies”, which are text files placed on your computer, to assist in analyzing data.  Cookies may also be stored in your web browser in association with your use of this Website to enhance your user experience.  These cookies do not contain personal information and do not compromise your personal information.  By using the Website, you consent to the processing of data about you by Google, the Firm, and third parties, in the manner and for the purposes set out in this Policy.
            Information You Submit
            If you submit a form, message, comment, email, registration, or other communication using this Website, your contact information will be collected by the Firm.  This information may include text characters, audio, video, and graphic formatting.  Your contact information including your name, email address, phone number may be used to respond to you, address your communication, to improve the Website, or for data collection purposes.

            The Firm does not knowingly collect personal information from children through the Website.  Users are cautioned, however, that the collection of personal information submitted through an email or online communication will be treated by the Firm as if it were submitted by an adult.  The Firm requires minor users of the Website to seek the assistance of an adult before visiting the Website or submitting any personal information.
            Securing Personal Information
            The Firm takes steps to secure the data it collects.  Any information sent to the Firm by form, message, comment, email, registration, or other communication through the Website is not secure or confidential.  If you choose to send information electronically to the Firm, you do so at your own risk.
            Outside Links
            This Website may contain links to other websites.  The inclusion of any link does not imply endorsement by the Firm of any linked site.  The Firm is not responsible for the privacy practices or the content of such third-party websites.  Use of any such linked website is at the user’s own risk.  You are encouraged to be aware when you leave the Website, and to read the privacy policy of every website that you visit.
            Transmission of Information Does Not Form an Attorney-Client Relationship
            The information contained in this website is for informational purposes only.  It is not and should not be construed as legal advice or legal opinion.  It is not an offer to represent you, nor is it intended to create an attorney-client relationship.  Any message you send to the Firm or any attorney of the Firm via the internet, through this Website, using email, electronic message, or other electronic communication format is not confidential and does not create an attorney-client relationship.
            Policy Changes
            This Policy may be updated from time to time at the discretion of the Firm.  Updates will be posted in this Policy.  The Firm does not sell your personally identifiable information to anyone. The Firm will not share your personally identifiable information with third parties except where: you have given your express consent; the Firm, in its good-faith judgment, determines it needs to provide your information to a third party in order to deliver services you requested; or disclosure is required by law or to protect the rights, property, or personal safety of personnel of the Firm, clients or members of the general public.
            Questions About This Policy
            If you have any questions about this Policy, please contact the Firm at Soho Square Abu Dhabi, Saadiyat Island: +971581785456
            </p>
    </div>
  )
}

export default Privacy
