import React from 'react';
import './Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from './Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

function Labourlaw() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Legal Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
          <h3>Labour Law</h3><br />
        Navigating the complexities of labour law is essential for maintaining a harmonious and legally compliant workplace. At True View, we offer a comprehensive range of labour law services tailored to support both employees and employers. Our team of legal experts provides practical solutions to manage workplace relations and ensure compliance with the latest regulations.
        </p>
        <div id='newxz'>
        <Swiper
           spaceBetween={30}
           slidesPerView={4}
           slidesPerGroup={1}
           navigation
           loop={true}
          //  autoplay={{
          //    delay: 2000,
          //    disableOnInteraction: true,
          //  }}
           modules={[Navigation]}
           breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }} // Use the Navigation and Autoplay modules
        >
          
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Ministry of Labour matters</h5><br />
              <p>
              Our team provides comprehensive support for matters involving the Ministry of Labour. We assist with regulatory compliance, representation in inspections and audits, and resolving issues related to labour law violations. Our goal is to help you navigate the regulatory landscape efficiently and effectively.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Employee and employer disputes</h5><br />
              <p>
              We offer expert legal assistance in resolving disputes between employees and employers. Our services include mediation, negotiation, and representation in litigation to ensure fair and equitable outcomes. We work diligently to protect your rights and interests while fostering a productive workplace environment.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Drafting service and employment contracts</h5><br />
              <p>
              Clear and comprehensive employment contracts are crucial for defining the terms and conditions of employment. We specialize in drafting service and employment contracts that are tailored to your business needs and compliant with labour laws. Our contracts cover all essential aspects, including job responsibilities, compensation, benefits, and termination clauses.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Hiring and termination management</h5><br />
              <p>
              Managing the hiring and termination processes is legally complex and sensitive. We provide expert guidance on best practices for recruitment, onboarding, and termination to ensure compliance with legal requirements and mitigate potential risks. Our services include drafting offer letters, handling redundancies, and managing disciplinary procedures.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'> 
              <h5>General advice on employment-related issues</h5><br />
              <p>Our team offers ongoing legal advice on a wide range of employment-related issues. From workplace policies and procedures to compliance with health and safety regulations, we provide practical solutions to help you maintain a positive and lawful working environment. Our goal is to support your business in managing its workforce effectively.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Privacy Policy (AR-EN)</h5><br />
              <p>With increasing concerns about data privacy, having a robust privacy policy is essential. We draft detailed privacy policies in both Arabic and English, ensuring that your practices comply with data protection laws and that your customers' personal information is handled responsibly. Our services include advising on data collection, storage, and sharing practices to mitigate risks and maintain compliance.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Independent contractor agreements</h5><br />
              <p>
              We assist in drafting and reviewing independent contractor agreements to ensure clarity and legal compliance. Our agreements outline the terms of engagement, scope of work, payment terms, and confidentiality obligations, protecting your business from potential disputes and liabilities.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
      </div>
      <div className='slide3'>
                    <h5 id='quote'>"Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work."</h5>
                    <p>Steve Jobs</p>
                </div>
      <Footer />
    </div>
  );
}

export default Labourlaw;