import React from 'react';
import legal from '../assets/lgbg.png';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import Footer from '../Pages/Footer';

const Sustainbility = () => {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Sustainability</h1> 
      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        Environmental sustainability is an increasingly important issue in today’s world, and law firms are no exception.<br /><br />
        The legal profession is often associated with paper-intensive work, and law firms are known for their extensive use of paper documents. However, there are many ways that law firms can reduce their paper usage and promote sustainable practices. This can include using digital document management systems, reducing the use of paper in meetings and presentations, and encouraging employees to print only when necessary. In addition to reducing paper usage, law firms can also promote energy efficiency in their operations. This can include using energy-efficient lighting and appliances, implementing recycling programs, and encouraging employees to use public transportation or carpool to work. Another way that law firms can promote environmental sustainability is by partnering with clients who share their values.
        </p>
      </div>
      <div className='slide3'>
                    <h5 id='quote'>"We never know the worth of water till the well is dry."</h5>
                    <p>Thomas Fuller</p>
                </div>
      <Footer />
    </div>
  )
}

export default Sustainbility
