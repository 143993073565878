import React from 'react';
import legal from '../assets/lgbg.png';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import Footer from '../Pages/Footer';

const Philosafy = () => {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Philosophy</h1> 
      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        Our company has cultivated a resilient legal system that is adept at managing and resolving complex legal matters. Our founders personally oversee each case and implement a hands-on strategy to ensure its successful resolution. We have a diverse customer base that spans numerous industries and countries, and we offer guidance to both individuals and businesses alike.<br /><br />
        <b>“The firm’s commitment is to provide transparent, honest and flexible legal service to its client- a reasonable cost for state of the art legal services which brings clients trust and confidence”.</b><br /><br />
        With a proven track record of success in our specialized fields, we have a high success rate and are proud to deliver exceptional results for our clients.
        </p>
      </div>
      <div className='slide3'>
                    <h5 id='quote'>"Stay faithful to that which exists within yourself."</h5>
                    <p>Andre Gide</p>
                </div>
      <Footer />
    </div>
  )
}

export default Philosafy
