import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function Onshore() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>UAE ONSHORE COMPANY REGISTRATION</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        We handle setting up various legal entities in the UAE comprehensively. Our UAE company registration service covers forming different company types, managing licenses, share transfers, officer appointments, drafting legal documents, and assisting with liquidation processes. Moreover, addressing the Economic Substance Regulations (ESR) in the UAE is crucial. These regulations, effective since April 30, 2019, mandate that 'relevant' registered companies adhere to substance requirements. Companies must assess their new obligations stemming from these ESR regulations.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Onshore;
