import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Home from './Pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import FinancialConsulting from './Pages/FinancialConsulting';
import LegalConsulting from './Pages/LegalConsulting';
import BusinessSetup from './Pages/BusinessSetup';
import Counsel from "./Pages/Counsel";
import CorporateCommerical from "./Pages/Corporate&Commerical";
import Ecommerce from "./Pages/E-commerce";
import Labourlaw from "./Pages/Labourlaw";
import DisputeResol from "./Pages/DisputeResol";
import RealEstate from "./Pages/RealEstate";
import CryptoLega from "./Pages/CryptoLega";
import BussSpon from "./Subpages/BussSpon";
import DocLegal from "./Subpages/DocLegal";
import FzOnshore from "./Subpages/FzOnshore";
import FzOfshore from "./Subpages/FzOfshore";
import Onshore from "./Subpages/Onshore";
import Proservice from "./Subpages/Proservice";
import EstatePlan from "./Subpages/EstatePlan";
import RetairPlan from "./Subpages/RetairPlan";
import InsurancePlan from "./Subpages/InsurencePlan";
import TaxPlan from "./Subpages/TaxPlan";
import InvestPlan from "./Subpages/InvesPlan"
import BussFinan from "./Subpages/BussFinan";
import Privacy from "./Subpages/Privacy";
import Terms from "./Subpages/Terms";
import Team from "./Subpages/Team";
import ScrollTop from "./Pages/ScrollTop";
import Philosafy from "./Subpages/philosafy";
import Sustainbility from "./Subpages/Sustainbility";
import Culture from "./Subpages/Culture";
import Callbtn from "./Subpages/Callbtn";

function App() {
  return (
    <>
      <Router>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/financialconsulting" element={<FinancialConsulting />} />
          <Route path="/legalconsulting" element={<LegalConsulting />} />
          <Route path="/businesssetup" element={<BusinessSetup />} />
          <Route path="/counsel" element={<Counsel />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/labourlaw" element={<Labourlaw />} />
          <Route path="/disputeresolution" element={<DisputeResol />} />
          <Route path="/realestate" element={<RealEstate />} />
          <Route path="/cryptolegal" element={<CryptoLega />} />
          <Route path="/bussinessponser" element={<BussSpon />} />
          <Route path="/doclegal" element={<DocLegal />} />
          <Route path="/fzgcc" element={<FzOnshore />} />
          <Route path="/fzofshore" element={<FzOfshore />} />
          <Route path="/onshore" element={<Onshore />} />
          <Route path="/proservice" element={<Proservice />} />
          <Route path="/estateplan" element={<EstatePlan />} />
          <Route path="/retairplan" element={<RetairPlan />} />
          <Route path="/investplan" element={<InvestPlan />} />
          <Route path="/insuranceplan" element={<InsurancePlan />} />
          <Route path="/taxplan" element={<TaxPlan />} />
          <Route path="/businessandfinance" element={<BussFinan />} />
          <Route path="/corporate&commerical" element={<CorporateCommerical />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="team" element={<Team/>} />
          <Route path='philosafy' element={<Philosafy />} />
          <Route path='sustainability' element={<Sustainbility />} />
          <Route path='culture' element={<Culture />} />
        </Routes>
        <Callbtn />
      </Router>
    </>
  );
}

export default App;
