import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function RetairPlan() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Financial Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        A financial consultant can assist individuals in preparing for a secure retirement by determining the amount of savings required to cover their living expenses. They provide projections and guidance to ensure a comfortable and financially stable future.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default RetairPlan;
