import React from 'react';
import './Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from './Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

function CryptoLega() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Legal Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
          <h3>Crypto Legal Services</h3><br />
        In the rapidly evolving world of cryptocurrency and blockchain technology, True View offers specialized legal services to help you navigate this complex and highly regulated landscape. Our Crypto Legal Services are designed to provide comprehensive legal support, ensuring that your operations are compliant and strategically sound.
        </p>
        <div id='newxz'>
        <Swiper
           spaceBetween={30}
           slidesPerView={4}
           slidesPerGroup={1}
           navigation
           loop={true}
          //  autoplay={{
          //    delay: 2000,
          //    disableOnInteraction: true,
          //  }}
           modules={[Navigation]}
           breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }} // Use the Navigation and Autoplay modules
        >
          
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Drafting of Legal Documents</h5><br />
              <p>
              We provide expert drafting services for all necessary legal documents related to your crypto and blockchain ventures. Whether you need terms of service, privacy policies, whitepapers, or partnership agreements, our team ensures that your documents are thorough, clear, and legally enforceable, protecting your interests and supporting your business objectives.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Advice on regulatory compliance issues and AML / KYC issues</h5><br />
              <p>
              Compliance with regulatory requirements is critical in the crypto industry. We provide comprehensive advice on regulatory compliance issues, including Anti-Money Laundering (AML) and Know Your Customer (KYC) regulations. Our team helps you develop and implement compliance programs that meet local and international standards, mitigating risks and ensuring lawful operations.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Market entry legal analysis and options advice for crypto and blockchain</h5><br />
              <p>
              We offer in-depth market entry legal analysis, helping you understand the regulatory environment and identify the best options for establishing your presence in this space. Our advice is tailored to your specific needs and goals, ensuring a smooth and compliant market entry.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Ensuring regulatory compliance</h5><br />
              <p>
              We offer ongoing support to ensure your business remains in compliance with all relevant laws and regulations. Our services include monitoring regulatory changes, advising on compliance strategies, and assisting with audits and reporting requirements.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
      </div>
      <div className='slide3'>
                    <h5 id='quote'>"Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work."</h5>
                    <p>Steve Jobs</p>
                </div>
      <Footer />
    </div>
  );
}

export default CryptoLega;
