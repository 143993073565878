import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function TaxPlan() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Financial Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        A financial consultant can offer valuable strategies to reduce a client's tax burden. These strategies may include leveraging tax deductions or exploring specific types of investment accounts.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default TaxPlan;
