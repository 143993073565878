// Slide.js or Slide.jsx
import React, { forwardRef } from 'react';

const Slide = forwardRef(({ children }, ref) => {
  return (
    <div ref={ref}>
      {children}
    </div>
  );
});

export default Slide;