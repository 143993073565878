import React from 'react';
import legal from '../assets/lgbg.png';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import Footer from '../Pages/Footer';

function Culture() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Culture</h1> 
      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        Clients come to us because they find our culture of integrity compatible and our legal expertise reliable. We approach legal challenges with integrity and adhere to a code of ethics that guides us as legal practitioners. Our solutions are based on moral discipline, impartiality, and accountability, ensuring stable and sustainable outcomes for our clients and the mindset to fully comply with our clients’ corporate culture and the law whilst maximizing our chance of success.
        </p>
      </div>
      <div className='slide3'>
                    <h5 id='quote'>"To win in the market place you must first win in the workplace."</h5>
                    <p>Doug Conant</p>
                </div>
      <Footer />
    </div>
  )
}

export default Culture
