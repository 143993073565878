import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function Proservice() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>PRO SERVICES</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        We've built strong and effective connections with key government officials in the UAE, ensuring precise and prompt service delivery. Our support includes handling business administration, licensing matters, product approvals, Ministry of Labour and Immigration procedures, work permits, visa applications, medical checks, Emirates ID assistance, visa cancellations, criminal and police liaison services, as well as employee visas and immigration affairs.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Proservice;
