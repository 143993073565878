import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function EstatePlan() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Financial Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        This service entails developing a comprehensive strategy for the distribution of assets after one's passing. Financial consultants play a crucial role in estate planning by advising clients on minimizing tax obligations and maximizing inheritances.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default EstatePlan;
