import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function InvestPlan() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Financial Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        When it comes to investment planning, financial consultants assess a client's risk tolerance and financial goals to determine the most suitable investment options. They often suggest a diversified portfolio of stocks, bonds, and mutual funds to help clients achieve long-term wealth growth.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default InvestPlan;
