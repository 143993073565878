import React from 'react';
import ftlogo from '../assets/footlogo.png';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { FaXTwitter, FaThreads  } from "react-icons/fa6";
import './Style.css';
import ScrollUp from './ScrollUp';

function Footer() {

    const iconStyle = {
        margin: '0 3px', // Adjust the value as needed for more or less space
        color: '#fff',
        transition: 'transform 0.2s'
      };

    return (
        <>
            <div className='row' id='slide6'>
                <div className='column' id='ft-col1'>
                    <Link to='/'><img className='ft-logo' href src={ftlogo} alt='logo'></img></Link>
                </div>
                <div className='column' id='ft-col2'>
                <h5>About</h5>
                    <h6><Link to='/#' style={{ color: 'white', textDecoration: 'none', lineHeight: '2'}} >Mission</Link></h6>
                    <h6><Link  to='/philosafy'  style={{ color: 'white', textDecoration: 'none', lineHeight: '1.3'}}>Philosophy</Link></h6>
                    <h6><Link  to='/culture' style={{ color: 'white', textDecoration: 'none', lineHeight: '1.3'}}>Culture</Link></h6>
                    <h6><Link  to='/sustainability' style={{ color: 'white', textDecoration: 'none', lineHeight: '1.3'}}>Sustainability</Link></h6>
                    <h6><Link  to='/team' style={{ color: 'white', textDecoration: 'none'}}>Our&nbsp;Team</Link></h6>
                </div>
                <div className='column' id='ft-col3'>
                    <h5><Link className='boxs' to='/legalconsulting'>Legal&nbsp;Consulting</Link></h5>
                    <Link className='boxs' to='/counsel'>External&nbsp;General&nbsp;Counsel</Link><br />
                    <Link className='boxs' to='/corporate&commerical'>Corporate&nbsp;&&nbsp;Commercial</Link><br />
                    <Link className='boxs' to='/ecommerce'>Ecommerce</Link>< br />
                    <Link className='boxs' to='/labourlaw'>Labour&nbsp;Law</Link>< br />
                    <Link className='boxs' to='/disputeresolution'>Dispute&nbsp;Resolution</Link>< br />
                    <Link className='boxs' to='/realestate'>Real&nbsp;Estate</Link>< br />
                    <Link className='boxs' to='/cryptolegal'>Crypto&nbsp;Legal&nbsp;Services</Link>
                </div>
                <div className='column' id='ft-col4'>
                    <h5><Link className='boxs' to='/financialconsulting'>Financial&nbsp;Consulting</Link></h5>
                    <Link className='boxs' to='/'>Estate&nbsp;Planning</Link>< br />
                    <Link className='boxs' to='/'>Retirement&nbsp;Planning</Link>< br />
                    <Link className='boxs' to='/'>Investment&nbsp;Planning</Link>< br />
                    <Link className='boxs' to='/'>Insurance&nbsp;Planning</Link>< br />
                    <Link className='boxs' to='/'>Tax&nbsp;Planning</Link>< br />
                    <Link className='boxs' to='/'>Business&nbsp;&&nbsp;Financial</Link>< br />
                </div>
                <div className='column' id='ft-col5'>
                    <h5><Link className='boxs' to='/businesssetup'>Business&nbsp;Setup</Link></h5>
                    <Link className='boxs' to='/'>Free&nbsp;Zone&nbsp;&&nbsp;Offshore</Link>< br />
                    <Link className='boxs' to='/'>Uae&nbsp;Onshore&nbsp;Company</Link>< br />
                    <Link className='boxs' to='/'>Document&nbsp;Leglaisation</Link>< br />
                    <Link className='boxs' to='/'>Pro&nbsp;Services</Link>< br />
                    <Link className='boxs' to='/'>Business&nbsp;Sponsership</Link>< br />
                    <Link className='boxs' to='/'>Freezone,&nbsp;Offshore&nbsp;&&nbsp;Gcc</Link>< br />
                </div>

                <div className='column' id='ft-col6'>
                    <h5>Address</h5>
                    <a href="mailto:info@trueview.ae" className='mail' style={{marginBottom: '8px'}}>info@trueview.ae</a><br />
                    <a href="tel:+971581785456" className='mail' >+971581785456</a>
                    <p id='qwsa'>Saadiyat Island,<br />Abu Dhabi, UAE.</p>
                    <h5>Follow Us</h5>
                    <a href="https://www.facebook.com/profile.php?id=61561059802532&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" style={iconStyle}>
                        <FaFacebook size={17} />
                    </a>
                    <a href="https://www.instagram.com/trueview.ae?igsh=MTB0dWRvcDRmNjJ3eA==" target="_blank" rel="noopener noreferrer" style={iconStyle}>
                        <FaInstagram size={17} />
                    </a>
                    <a href="https://www.tiktok.com/@true.view67?_t=8o5xHFfpeEq&_r=1" target="_blank" rel="noopener noreferrer" style={iconStyle}>
                        <FaTiktok size={17} />
                    </a>
                    {/* <a href="https://www.youtube.com/@Trueview2024" target="_blank" rel="noopener noreferrer" style={iconStyle}>
                        <FaYoutube size={17} />
                    </a> */}
                    <a href="https://x.com/trueview2024?s=11" target="_blank" rel="noopener noreferrer" style={iconStyle}>
                        <FaXTwitter size={17} />
                    </a>
                    <a href="https://www.threads.net/@trueview.ae" target="_blank" rel="noopener noreferrer" style={iconStyle}>
                        <FaThreads size={17} />
                    </a>
                    <a href="https://www.linkedin.com/company/trueview-for-legal-financial-consulting" target="_blank" rel="noopener noreferrer" style={iconStyle}>
                        <FaLinkedin size={17} />
                    </a>
                </div>
            </div>
            <div className='row' id='slide6'>
                <div className='column' style={{ width: '33%' }}>
                    <p id='p-ft'>© 2024 TrueView. All Rights Reserved</p>
                </div>
                <div className='column' style={{ width: '33%', paddingLeft: '9%' }}>
                    <p style={{fontFamily: 'Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif', fontSize: '11px'}}>Designed\<a className='linkk' href="https://infinitegraphix.ae" >InfiniteGraphix</a>&nbsp;&nbsp;Developed\<a className='linqq' href="https://uae-emc.com">EMC</a></p>
                </div>
                <div className='column' style={{ width: '34%', paddingLeft: '11%'}}>
                    <p><Link className='linkk' to='/terms'>Terms&Conditions&nbsp;</Link> <Link className='linkk' to='#'>Disclaimer&nbsp;</Link> <span><Link className='linkk' to='/privacy'>PrivacyPolicy</Link></span></p>
                </div>
                < ScrollUp />
            </div>
        </>
    )
}

export default Footer