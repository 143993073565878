import React from 'react';
import './Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from './Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

function Counsel() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>Legal Consulting</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
          <h3>External General Counsel</h3><br />
          At True View, we understand that not every business has the resources or need for a full-time, in-house legal team. Our External General Counsel services provide you with the expertise and support of an experienced legal team on an as-needed basis. This flexible solution is ideal for businesses seeking high-quality legal advice and representation without the overhead costs associated with maintaining an in-house counsel.
        </p>
        <div id='newxz'>
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          slidesPerGroup={1}
          navigation
          loop={true}
          // autoplay={{
          //   delay: 2000,
          //   disableOnInteraction: false,
          // }}
          modules={[Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
        >
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Comprehensive Legal Support</h5><br />
              <p>
                Our External General Counsel service offers comprehensive legal support tailored to your business needs.
                Whether you require assistance with corporate governance, contract negotiation, compliance issues,
                or any other legal matters, our team is here to provide expert guidance and practical solutions.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Cost-Effective Solution</h5><br />
              <p>
                Engaging an External General Counsel is a cost-effective way to access top-tier legal expertise
                without the expense of a full-time legal department. Our flexible service model allows you to scale
                legal support up or down based on your current needs, ensuring you only pay for the services you require.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Contract Management</h5><br />
              <p>
                Effective contract management is vital for any business. Our services include drafting, reviewing, and
                negotiating contracts to ensure they are clear, enforceable, and protect your interests.
                We also assist in monitoring contract performance and compliance,
                reducing the risk of disputes and ensuring contractual obligations are met.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Employment Law Advice</h5><br />
              <p>
                We provide expert guidance on all aspects of employment law, including hiring practices, employee relations, 
                compliance with labour laws, and handling employment disputes. Our goal is to help you create a fair and 
                compliant workplace that supports your business objectives.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'> 
              <h5>Proactive Risk Management</h5><br />
              <p>We help you identify and mitigate potential legal risks before they become issues. 
                Our proactive approach to risk management includes regular reviews of your legal practices and policies, ensuring 
                compliance with the latest regulations and reducing the likelihood of costly disputes</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Seamless Integration</h5><br />
              <p>Our External General Counsel seamlessly integrates with your existing team, providing consistent and 
                reliable legal support. We become an extension of your business, offering dedicated and personalized service 
                that is always aligned with your best interests</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Crisis Management and Dispute Resolution</h5><br />
              <p>In times of crisis or dispute, having experienced legal counsel is crucial. Our team provides strategic
                advice and representation to manage crises effectively and resolve disputes promptly. 
                We aim to protect your reputation and minimize the impact on your business operations</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'> 
              <h5>Legal Translation and Notarization Services</h5><br />
              <p>Accurate and reliable legal translation is crucial for businesses operating in multiple languages. We offer professional legal translation services to ensure your documents are accurately translated while maintaining legal integrity. Our services cover contracts, agreements, regulatory documents, and more, available in multiple languages to meet your needs. 
                In addition, notarization is essential for validating legal documents. We provide notarization services to authenticate and certify your legal documents, ensuring their validity and acceptance in legal proceedings. Our services include notarizing contracts, affidavits, deeds, and other important documents
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='column' id='Counsel2'>
              <h5>Business Expansion Support</h5><br />
              <p>Expanding your business into new markets requires careful planning and legal oversight. 
                We provide legal advice and support for mergers and acquisitions, joint ventures, and strategic alliances. 
                Our goal is to help you navigate the complexities of business expansion while minimizing risks and maximizing opportunities</p>
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
      </div>
     
      <div className='slide3'>
                    <h5 id='quote'>"Perfection is not attainable, but if we chase perfection we can catch excellence."</h5>
                    <p>Vince Lombardi</p>
                </div>
      
      <Footer />
    </div>
  );
}

export default Counsel;
