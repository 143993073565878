import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function DocLegal() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>DOCUMENT LEGALISATION</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        We can support you with various legal document services, such as document certification, legalizing documents for use in the UAE, authenticating documents at the UAE Ministry of Foreign Affairs and UAE Ministry of Justice, notarizing documents, assisting with document legalization at consulates and embassies, handling Powers of Attorney, and ensuring companies comply with the law. Also, we've summarized the key rules to guide you through the complexities of the ESR and covered important aspects to aid in achieving compliance.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default DocLegal;
