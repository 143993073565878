import React from 'react';
import '../Pages/Style.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import legal from '../assets/lgbg.png';
import Footer from '../Pages/Footer';

function FzOfshore() {
  return (
    <div className='legal'>
      <Link to='/'><img className='tv-logo' src={logo} alt='logo' /></Link>
      <img className='le-bg' src={legal} alt='leg' />
      <h1>FREEZONE, OFFSHORE & GCC COMPANY REGISTRATION</h1> 

      <div className='row' id='Counsel1'>
        <p id='mainpr'>
        We can assist with various services such as Free Zone and Offshore company incorporation in the GCC and abroad, providing registered agent services for RAKICC and ADGM, handling license renewals, amendments, share transfers, appointment and removal of officers, drafting and amending memorandum and articles of association, as well as liquidation and de-registration. Let me know if you need more information on these company setup and management services!
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default FzOfshore;
